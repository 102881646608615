<template>
    <cover-page-template :fluidContainer="true">
        <b-col xl="6" lg="8" md="10" sm="12" offset-xl="3" offset-lg="2" offset-md="1" class="pb-150 pt-100">
            <b-card>
                <router-link :to="{ name: 'homepage' }">
                    <img class="main-logo" src="/img/logo.png" alt="Cluji"/>
                </router-link>
                <h1 class="mt-5 mb-2">{{ title }}</h1>
                <p>{{ message }}</p>

                <div class="mt-5">
                    <slot/>
                </div>
            </b-card>
        </b-col>
    </cover-page-template>
</template>

<script>
    const CoverPageTemplate = () => import('./CoverPageTemplate');

    export default {
        name: 'ModalPageTemplate',
        components: {CoverPageTemplate},
        props: {
            title: {
                type: String,
                default: ""
            },
            message: {
                type: String,
                default: ""
            }
        }
    };
</script>

<style scoped>
    img.main-logo {
        width: 200px;
        filter: drop-shadow(0 0 0.25rem black);
    }
</style>